<template>
    <span :class="$vuetify.breakpoint.mobile ? '' : 'pa-5'">
        <v-row>
            <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '11'" class="mx-auto">
                <v-row dense>
                    <v-col class="mx-auto" cols="12">
                        <v-chip-group v-model="selected_cat" active-class="orange white--text" mandatory
                            @change="getItems">
                            <v-chip v-for="cat in categories" :key="cat.id"
                                :color="$vuetify.theme.isDark ? 'black' : 'white'">
                                {{ cat.name }}
                            </v-chip>
                        </v-chip-group>
                    </v-col>
                </v-row>
                <items-loading v-if="loading"></items-loading>

                <v-row v-else>
                    <v-col v-for="item in items.data" :key="item.id" cols="6" sm="3">
                        <item-card :item="item" @selected="addItem"></item-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="2">
                        <v-select v-model="page" :items="pages" color="orange" item-color="orange" label="Pages"
                            @change="getItems"></v-select>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" sm="3">
                <v-navigation-drawer v-model="$store.state.cartSideNav" :clipped="false" app floating right
                    width="400px">
                    <v-card flat>
                        <v-card-title :class="$vuetify.breakpoint.mobile ? '' : 'pt-9'">
                            <v-btn v-if="$vuetify.breakpoint.mobile" text x-small
                                @click="$store.state.cartSideNav = false"><v-icon>mdi-arrow-left</v-icon>Back
                            </v-btn>

                            <h5 v-if="cartItems.length" class="font-weight-light" style="font-size: 1.2rem">
                                Current Order: {{ cartItems.length }} items
                            </h5>
                            <v-spacer></v-spacer>

                            <v-btn v-if="!$vuetify.breakpoint.mobile && cartItems.length" color="red" rounded small text
                                @click="
                                    cartItems = [];
                                amountPaid = 0;
                                cartStep = 1;
                                total_discount = 0;
                                temp_discount = 0;
                                ">Clear
                                order <v-icon>mdi-delete-outline</v-icon></v-btn>
                        </v-card-title>
                        <v-divider v-if="cartItems.length"></v-divider>
                        <v-card-text style="height: 48vh; overflow: auto; padding: 0 !important">
                            <v-list v-if="cartItems.length">
                                <cart-item v-for="(item, index) in cartItems" :key="item.id" :index="index" :item="item"
                                    @changed="itemChanged" :total_discount="total_discount"
                                    @remove="Removeitem"></cart-item>
                            </v-list>
                            <span v-else class="mt-4 pt-5" style="margin-top: 15em !important">
                                <center>
                                    <div class="orange lighten-5" style="width: 80px; height: 80px; border-radius: 50%">
                                        <v-icon class="mt-5" color="orange" x-large>mdi-cart-remove</v-icon>
                                    </div>
                                    <h4 class="text--disabled pa-3 font-weight-light">
                                        The order is empty, please add some items
                                    </h4>
                                </center>
                            </span>
                        </v-card-text>

                        <v-card-actions style="height: 40vh"
                            :class="$vuetify.theme.isDark ? 'pa-2 black' : 'pa-2 grey lighten-5'">
                            <v-card-text v-if="!showAccounts"
                                :class="$vuetify.theme.isDark ? 'pa-0 black' : 'pa-0 grey lighten-5'">
                                <v-stepper v-model="cartStep" class="pa-0" style="box-shadow: none !important">
                                    <v-stepper-items class="pa-0">
                                        <v-stepper-content class="pa-0" step="1">
                                            <v-card-title class="pb-0 pt-0 text--disabled"
                                                style="font-size: 14px">Subtotal: <v-spacer></v-spacer><strong :class="$vuetify.theme.isDark ?
                                                    'white--text' :
                                                    'black--text'">{{ business.currency.code }}{{ subTotal |
                                                        toMoney }}</strong></v-card-title>
                                            <v-card-title class="pb-0 pt-0 text--disabled"
                                                style="font-size: 14px">Discount: <v-spacer></v-spacer><strong :class="$vuetify.theme.isDark ?
                                                    'white--text' :
                                                    'black--text'">
                                                    -{{ business.currency.code }}{{ discount | toMoney
                                                    }}</strong></v-card-title>
                                            <v-card-title class="pb-0 pt-0 text--disabled" style="font-size: 14px">Net
                                                Total: <v-spacer></v-spacer><strong :class="$vuetify.theme.isDark ?
                                                    'white--text' :
                                                    'black--text'">{{ business.currency.code }}{{ net_amount |
                                                        toMoney }}</strong></v-card-title>
                                            <v-card-title class="pb-0 pt-0 text--disabled" style="font-size: 14px">Total
                                                Sales Tax: <v-spacer></v-spacer><strong :class="$vuetify.theme.isDark ?
                                                    'white--text' :
                                                    'black--text'">{{ business.currency.code }}{{ total_tax |
                                                        toMoney }}</strong></v-card-title>
                                            <p style="width: 100%; border-bottom: 1px dashed grey"></p>

                                            <v-card-title class="pb-0 pt-0">Amount Due: <v-spacer></v-spacer><strong
                                                    :class="$vuetify.theme.isDark ?
                                                        'white--text' :
                                                        'black--text'">
                                                    {{ business.currency.code }}{{ amount_due | toMoney }}
                                                </strong></v-card-title>

                                            <v-btn :dark="amount_due > 0 && !inventoryError"
                                                :disabled="amount_due <= 0 || inventoryError" block
                                                class="mt-3 text-capitalize" color="orange" depressed rounded x-large
                                                @click="
                                                    cartStep = 2;
                                                GetWallet();
                                                ">Proceed
                                                to Payment<v-icon>mdi-arrow-right</v-icon></v-btn>
                                        </v-stepper-content>
                                        <v-stepper-content :class="$vuetify.theme.isDark ?
                                            'pa-0 black' :
                                            'pa-0 grey lighten-5'" step="2">
                                            <span v-if="CAN_POS" class="mb-3">
                                                <v-card-title class="pa-0">
                                                    <v-btn rounded small text @click="cartStep = 1"><v-icon
                                                            small>mdi-arrow-left</v-icon> back</v-btn>
                                                    <v-spacer></v-spacer>
                                                    <h4 class="text-center">
                                                        Amount:{{ business.currency.code }}{{ amount_due | toMoney
                                                        }}
                                                    </h4>
                                                </v-card-title>

                                                <v-tabs color="orange">
                                                    <v-tab @click="showCheckout = false">
                                                        Save Sale
                                                    </v-tab>
                                                    <v-tab @click="
                                                        showCheckout = true;
                                                    GetWallet();
                                                    ">
                                                        Charge Customer
                                                    </v-tab>
                                                </v-tabs>

                                                <v-text-field v-if="cartStep > 1 && !showCheckout" v-model="amountPaid"
                                                    :hint="'Change:' + change" :prefix="business.currency.code"
                                                    class="mt-2" color="orange" dense label="Amount Paid" outlined
                                                    persistent-hint type="number"
                                                    @focus="$store.state.barcodeMode = false"></v-text-field>

                                                <template v-if="!showCheckout">
                                                    <v-form ref="payments_form">
                                                        <v-row v-for="(payment, index) in payments" :key="index"
                                                            class="mt-0" dense>
                                                            <V-col class="mb-0" cols="12" sm="6">
                                                                <v-autocomplete v-model="payment.account_id"
                                                                    :disabled="creating_transaction" :items="accounts"
                                                                    :rules="[rules.required]" auto-select-first
                                                                    class="mt-0" color="orange" dense
                                                                    item-color="orange" item-text="account_name"
                                                                    item-value="id" label="Paid into"
                                                                    outlined></v-autocomplete>
                                                            </V-col>
                                                            <v-col class="mt-0" cols="12" sm="6">
                                                                <v-text-field v-model="payment.amount"
                                                                    :prefix="business.currency.code"
                                                                    :rules="[rules.required]" class="mt-0"
                                                                    color="orange" dense label="Amount" min="0" outlined
                                                                    type="number">
                                                                    <template v-slot:append-outer>
                                                                        <v-btn :disabled="index === 0" color="red" icon
                                                                            small @click="removePayment(index)"><v-icon
                                                                                small>mdi-delete-outline</v-icon></v-btn>
                                                                    </template>
                                                                </v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-form>

                                                    <v-row class="mt-0" dense>
                                                        <v-col cols="12" sm="8">
                                                            <v-btn class="ma-0" v-if="payments.length < 2" block
                                                                color="orange" text x-small
                                                                @click="addPaymentItem"><v-icon
                                                                    x-small>mdi-plus</v-icon> payment
                                                                row</v-btn>
                                                        </v-col>
                                                        <v-col cols="12" sm="4">
                                                            <v-menu :close-on-click="false" ref="menu"
                                                                :close-on-content-click="false" min-width="400px"
                                                                transition="scale-transition">
                                                                <template v-slot:activator="{ attrs, on }">
                                                                    <v-btn block color="orange" class="ma-0" outlined
                                                                        rounded small v-bind="attrs" v-on="on">More
                                                                        <v-icon small>mdi-switch</v-icon></v-btn>
                                                                </template>
                                                                <v-card>
                                                                    <v-card-title>
                                                                        <h4 class="font-weight-light">
                                                                            Other Inputs
                                                                        </h4>
                                                                        <v-spacer></v-spacer>
                                                                        <v-btn color="red lighten-5 red--text" depressed
                                                                            rounded small @click="closeMenu">close
                                                                            <v-icon small>mdi-close</v-icon>
                                                                        </v-btn>
                                                                    </v-card-title>
                                                                    <v-alert class="mx-3" style="width: 400px" outlined
                                                                        type="warning" prominent border="left">

                                                                        Note that applying a discount here will replace
                                                                        discount applied to each item with this new
                                                                        discount

                                                                    </v-alert>
                                                                    <v-form ref="more_form">
                                                                        <v-card-text>
                                                                            <!-- <v-switch inset class="mx-2"
                                                                            v-model="isDiscountPercent"
                                                                            label="Enter discount %"
                                                                            color="orange"></v-switch> -->
                                                                            <v-text-field v-model="temp_discount"
                                                                                :disabled="!settings.can_change_discounts"
                                                                                prefix="%" type="number" class="mt-2"
                                                                                color="orange"
                                                                                :rules="[rules.validValue]" label="
                                                                                
                                                                                Discount Percent"
                                                                                outlined></v-text-field>
                                                                            <v-menu ref="menu1" v-model="dateMenu"
                                                                                max-width="290px" min-width="auto"
                                                                                offset-y transition="scale-transition">
                                                                                <template
                                                                                    v-slot:activator="{ on, attrs }">
                                                                                    <v-text-field v-model="temp_date"
                                                                                        label="Date" outlined
                                                                                        v-bind="attrs"
                                                                                        :disabled="!settings.change_change_date"
                                                                                        v-on="on"></v-text-field>
                                                                                </template>
                                                                                <v-date-picker :min="cutoffDate"
                                                                                    v-model="temp_date" no-title
                                                                                    @input="dateMenu = false"
                                                                                    color="orange"></v-date-picker>
                                                                            </v-menu>

                                                                            <v-textarea v-model="temp_note" auto-grow
                                                                                color="orange" label="Note" outlined
                                                                                rows="2"></v-textarea>

                                                                        </v-card-text>
                                                                        <v-card-actions>
                                                                            <v-btn block class="mt-3 text-capitalize"
                                                                                dark color="orange" depressed rounded
                                                                                x-large @click="saveChanges"
                                                                                :loading="is_saving">
                                                                                Save changes</v-btn>
                                                                        </v-card-actions>
                                                                    </v-form>
                                                                </v-card>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>

                                                    <span style="transition: 0.3s ease-in-out">
                                                        <v-btn :loading="creating_transaction" block class="mt-1"
                                                            color="orange" depressed outlined rounded x-large
                                                            @click="handleSaveSale">Save sale</v-btn>
                                                    </span>
                                                </template>

                                                <span v-if="showCheckout" style="transition: 0.3s ease-in-out">
                                                    <v-alert v-if="!wallet" class="blue lighten-5 blue--text" dense>
                                                        <h3>You do not have a wallet</h3>
                                                        <small>To instantly charge customers via mobile money,
                                                            you would need to signup for the Wallet</small>
                                                    </v-alert>
                                                    <span v-else>
                                                        <h2 class="font-weight-light text-center mb-2">
                                                            Charge via
                                                            {{ business.country.code.toLowerCase() === 'gh' ?
                                                                'mobile money' : 'Mpesa' }}
                                                        </h2>
                                                        <v-select v-if="
                                                            business.country.code.toLowerCase() === 'gh'
                                                        " v-model="network" :items="networks" color="orange" dense
                                                            item-color="orange" label="Select network"
                                                            outlined></v-select>
                                                        <v-text-field v-if="network" v-model="phone_number"
                                                            color="orange" dense filled outlined
                                                            placeholder="Customer Phone number" type="tel"
                                                            @keydown.enter="resolveCustomer"></v-text-field>
                                                        <v-btn :dark="phone_number.length >= 10"
                                                            :disabled="phone_number.length < 10" block color="orange"
                                                            depressed rounded x-large @click="resolveCustomer">
                                                            Initiate Charge
                                                        </v-btn>
                                                    </span>
                                                </span>
                                            </span>

                                            <v-alert v-else color="orange lighten-5 orange--text" dense>
                                                <h2>Subscription Expired!</h2>
                                                <p>
                                                    This business is on the basic plan, contact the owner
                                                    of the business to upgrade their plan on Built.<br />
                                                    if you are the owner of this business({{ business.name }}), log
                                                    on
                                                    to Built to upgrade your subscription
                                                    plan.
                                                </p>
                                                <v-btn color="orange" small text
                                                    @click="cartStep = 1"><v-icon>mdi-arrow-left</v-icon>back</v-btn>
                                            </v-alert>
                                        </v-stepper-content>
                                    </v-stepper-items>
                                </v-stepper>
                            </v-card-text>
                        </v-card-actions>
                    </v-card>
                </v-navigation-drawer>
            </v-col>
        </v-row>

        <v-dialog v-model="showConfirm" :persistent="$store.state.momo.progress" width="400">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text v-if="!$store.state.momo.progress">
                    <center v-if="resolving_user">
                        <v-progress-circular color="orange" indeterminate size="80"></v-progress-circular>
                    </center>
                    <span v-else>
                        <h2 class="text-center pa-2 font-weight-light">
                            Charge <strong>{{ customer_name }}</strong>
                            {{ business.currency.code }}{{ amount_due | toMoney }} on
                            {{ network }}/{{ phone_number }} ?
                        </h2>

                        <v-btn rounded :loading="creating_transaction" block color="orange" dark depressed large
                            tabindex="0" x-large @click="createTransaction">yes,proceed
                            <v-icon>mdi-check</v-icon></v-btn>
                    </span>
                </v-card-text>
                <v-card-text v-else class="text-center">
                    <v-progress-circular color="orange" indeterminate size="70"></v-progress-circular>
                    <p class="text--disabled">
                        Waiting for {{ customer_name }} to authorise the payment...
                    </p>

                    <v-btn class="text--disabled" color="default" small text
                        @click="$store.state.momo.progress = false">cancel</v-btn>
                </v-card-text>

                <v-alert v-if="$store.state.momo.failed" color="red lighten-5 red--text text-center">
                    {{ $store.state.momo.message }}
                </v-alert>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="error" color="error">{{ error_message }}</v-snackbar>

        <v-bottom-navigation v-if="$vuetify.breakpoint.mobile" app background-color="orange" dark fixed>
            <h2 class="pt-3 pl-3">
                {{ business.currency.code }}{{ amount_due | toMoney }}
            </h2>
            <v-spacer></v-spacer>
            <v-btn color="orange lighten-2" dark @click="$store.state.cartSideNav = true">Checkout
                <v-icon>mdi-arrow-right</v-icon></v-btn>
        </v-bottom-navigation>
    </span>
</template>

<script>
import ItemsLoading from "../components/ItemsLoading";
import ItemCard from "../components/itemCard";
import CartItem from "../components/cartItem";
import store from "../store";
import { cut_off_date } from "../utils";

export default {
    name: "Cart",
    components: {
        CartItem,
        ItemCard,
        ItemsLoading
    },
    data() {
        return {
            dateMenu: false,
            amountPaid: 0,
            canReferesh: true,
            showAccounts: false,
            accounts: [],
            payments: [],
            date: "",
            temp_date: "",
            account_id: null,
            creating_transaction: false,
            error: false,
            error_message: "",
            showConfirm: false,
            isDiscountPercent: true,
            is_saving: false,
            total_discount: 0,
            temp_discount: 0,
            note: "",
            temp_note: "",
            resolving_user: false,
            network: "MTN",
            customer_name: "",
            phone_number: "",
            menuOpen: false,
            networks: [{
                text: "AirtelTigo",
                value: "AirtelTigo",
            },
            {
                text: "MTN",
                value: "MTN",
            },
            {
                text: "Vodafone",
                value: "VODAFONE",
            },
            ],

            pNetworks: {
                AirtelTigo: "ATL",
                MTN: "MTN",
                VODAFONE: "VOD",
            },
            showCheckout: false,
            wallet: null,
            checkingWallet: false,
            cartStep: 1,
            selectedTaxes: [],
            cartItems: [],
            page: 1,
            loading: true,
            categories: [],
            selected_cat: null,
            items: [],
            discount_percent: true,
            rules: {
                required: (value) => !!value || "Field is required",
                validValue: value => value <= 100 && value >= 0 ||
                    'Value should not be greater than 100 and less than 0',

            },
            discount_value: 0,
            all: {
                id: null,
                name: "All",
            },
        };
    },
    computed: {
        cutoffDate() {
            return cut_off_date()
        },
        settings() {
            return this.$store.state.settings;
        },
        totalPayments() {
            let sum = 0;

            this.payments.forEach((payment) => {
                sum += Number(payment.amount);
            });

            return sum;
        },

        discount() {
            let sum = 0;

            this.prepedItems.forEach((item) => {
                sum += Number(item.discount_amount);
            });

            return sum;
        },
        change() {
            if (this.amountPaid > this.amount_due) {
                return (Number(this.amountPaid) - Number(this.amount_due)).toFixed(2);
            }

            return 0;
        },

        CAN_POS() {
            return this.business && this.business.subscription.guards.POS;
        },
        SetAccount_id() {
            return this.$store.state.account_id;
        },
        inventoryError() {
            const badItems = this.cartItems.filter(
                (item) => item.track_inventory && item.invoice_quantity > item.quantity
            );
            return badItems.length > 0;
        },

        selectedCategory() {
            return this.categories[this.selected_cat];
        },

        prepedItems() {
            let list = [];
            this.cartItems.forEach((item) => {
                list.push({
                    id: item.id,
                    amount: item.amount,
                    tax_amount: item.tax_amount,
                    applied_taxes: item.applied_taxes,
                    quantity: item.invoice_quantity,
                    discount_amount: item.discount_amount,
                    unit_price: item.unit_price,
                    description: item.description,
                    amount_due: item.amount_due,
                    item_unit_price: item.real_unitPrice ? item.real_unitPrice : 0,
                });
            });
            return list;
        },
        hasWallet() {
            return (
                this.wallet &&
                this.wallet.status &&
                this.wallet.data.status.toLowerCase() !== "suspended"
            );
        },

        net_amount() {
            return this.subTotal - this.discount;
        },

        amount_due() {
            const amount = Number(this.net_amount) + Number(this.total_tax);

            this.payments = [{
                account_id: this.account_id,
                amount: Number(amount.toFixed(2)),
            },];

            return Number(amount.toFixed(2));
        },
        total_tax() {
            let sum = 0;

            this.prepedItems.forEach((item) => {
                sum += Number(item.tax_amount);
            });

            return sum;

            //
            // if (this.taxes.length) {
            //   this.taxes.forEach(tax => {
            //
            //
            //     if (tax.type.toLowerCase() === "flat") {
            //
            //       sum += (Number(tax.rate) / 100) * Number(this.net_amount)
            //     } else {
            //       const subTax = tax.sub_rate > 0 ? Number(tax.sub_rate / 100) * Number(this.net_amount) : 0;
            //
            //       const compoundAmount = this.net_amount + subTax;
            //
            //       const totaltax = Number(tax.rate / 100) * compoundAmount;
            //
            //       sum += subTax + totaltax;
            //
            //
            //     }
            //
            //   });
            //
            //
            // }
            // return sum;
        },

        setUpTaxes() {
            return this.$store.state.taxes;
        },
        taxes() {
            return this.$store.state.user.current_business.taxes.filter((tax) =>
                this.setUpTaxes.includes(tax.id.toString())
            );
        },

        subTotal() {
            let sum = 0;
            this.cartItems.forEach((item) => {
                sum += Number(item.unit_price) * item.invoice_quantity;
            });

            return sum;
        },
        business() {
            return this.$store.state.user.current_business;
        },
        search() {
            return this.$store.state.search;
        },
        pages() {
            let list = [];
            for (let i = 1; i <= this.items.last_page; i++) {
                list.push({
                    value: i,
                    text: "Page " + i,
                });
            }

            return list;
        },
    },
    watch: {
        search() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getItems();
            }, 1000);
        },
        isDiscountPercent(newval) {
            this.total_discount = 0
            // this.discountComputation();
        },
    },
    methods: {
        closeMenu() {
            this.$refs.menu.isActive = false;
            this.temp_note = this.note
            this.temp_date = this.date
            this.temp_discount = this.total_discount
        },
        determineItemDiscount(item) {
            if (item.discount_amount) return item.discount_amount
            else if (this.total_discount) return (this.total_discount / 100) * item.selling_price
            return 0
        },
        saveChanges() {
            if (this.$refs.more_form.validate()) {
                this.is_saving = true
                this.note = this.temp_note
                this.date = this.temp_date
                this.total_discount = this.temp_discount
                this.cartItems = this.cartItems.map(item => {
                    if (this.isDiscountPercent) {
                        item.discount_amount = (this.total_discount / 100) * Number(item.unit_price) *
                            item.invoice_quantity
                    } else {
                        item.discount_amount = this.total_discount * item.invoice_quantity
                    }
                    return item
                })
                this.$refs.menu.isActive = false
                this.is_saving = false
            }
        },
        discountComputation() {
            if (this.isDiscountPercent) {
                this.discount = ((this.discount / this.unit_price) * 100).toFixed(2);
            } else {
                this.discount = (this.discount / 100) * this.unit_price;
            }
        },
        todayDate() {
            let today = new Date();
            let year = today.getFullYear();
            let day = today.getDate();
            let month = today.getMonth() + 1;

            day = day < 10 ? "0" + day : day;

            month = month < 10 ? "0" + month : month;

            return year + "-" + month + "-" + day;
        },

        removePayment(index) {
            this.payments.splice(index, 1);
            this.payments[0].amount = this.amount_due;
        },

        addPaymentItem() {
            this.payments.push({
                account_id: null,
                amount: 0,
            });
        },
        handleSaveSale() {
            this.saveSale();
        },

        saveSale() {
            if (this.inventoryError) {
                this.error_message = "Inventory error, please check and try again";
                this.error = true;
                return;
            }

            if (this.totalPayments != this.amount_due) {
                this.error_message =
                    "Payments error: the sum of all payment amounts should be the same as the amount due, please correct the payment amounts and try again.";
                this.error = true;
                return;
            }

            if (!this.$refs.payments_form.validate()) {
                this.error_message =
                    "Payments error: Empty field detected in payment rows, removed rows with empty fields or enter amounts to continue.";
                this.error = true;

                return;
            }

            this.creating_transaction = true;
            store.state.momo.failed = false;
            store.state.momo.progress = false;
            const sale = {
                entry_date: this.date,
                network: this.network,
                phone_number: this.phone_number,
                subtotal: this.subTotal.toFixed(2),
                discount_amount: this.discount,
                amount_due: this.amount_due.toFixed(2),
                change: this.change,
                taxes: "",
                note: this.note,
                tax_amount: this.total_tax.toFixed(2),
                items: this.prepedItems,
                payments: this.payments,
            };

            window.axios
                .post("/api/v2/pos/sale", sale)
                .then((res) => {
                    this.creating_transaction = false;
                    this.$store.state.printURL =
                        store.state.baseUrl + "/printposreceipt/" + res.data.enc_id;
                    this.$store.state.showPrint = true;
                    this.note = "";
                    this.date = this.todayDate();

                    this.cartItems = [];
                    this.amountPaid = 0;
                    this.cartStep = 1;
                    this.total_discount = 0
                    this.temp_discount = 0
                })
                .catch((error) => {
                    this.error_message =
                        error.response.status === 302 ?
                            error.response.data :
                            "Something went wrong, could not process the request";
                    this.error = true;
                    this.creating_transaction = false;
                });
        },

        resolveCustomer() {
            this.showConfirm = true;

            if (this.network === "mpesa") {
                this.customer_name = "this customer";
                this.resolving_user = false;
                return;
            }
            this.resolving_user = true;
            this.creating_transaction = false;
            store.state.momo.failed = false;
            store.state.momo.progress = false;

            const code = this.pNetworks[this.network];
            window.axios
                .get(
                    "/api/resolveaccount?account_number=" +
                    this.phone_number +
                    "&bank_code=" +
                    code
                )
                .then((res) => {
                    this.customer_name = res.data.account_name;
                    this.resolving_user = false;
                })
                .catch((error) => {
                    this.error_message =
                        error.response.status == 302 ?
                            error.response.data :
                            "Something went wrong, we could not process the transaction please check and try again";
                    this.error = true;
                    this.resolving_user = false;
                    this.showConfirm = false;
                });
        },
        createTransaction() {
            this.creating_transaction = true;
            store.state.momo.failed = false;
            store.state.momo.progress = false;
            const sale = {
                network: this.network,
                phone_number: this.phone_number,
                subtotal: this.subTotal,
                discount_amount: this.discount,
                amount_due: this.amount_due,
                taxes: this.selectedTaxes.toString(),
                tax_amount: this.total_tax,
                items: this.prepedItems,
            };

            window.axios
                .post("/api/pos/transactions", sale)
                .then((res) => {
                    this.creating_transaction = false;

                    this.$store.state.sale_enc_id = res.data.sale_id;

                    const trans = res.data.transaction;

                    if (trans.code !== "00") {
                        this.error_message = trans.data.msg;
                        this.error = true;
                        this.showConfirm = false;
                    } else {
                        const ch =
                            this.network.toLowerCase() === "mpesa" ?
                                "TANDA-MPESA_" + trans.reference :
                                "debitconfirmation_" + trans.reference;
                        this.$store.state.momo.progress = true;
                        this.startListening(ch);
                    }
                })
                .catch((error) => {
                    this.error_message =
                        error.response.status === 302 ?
                            error.response.data :
                            "Something went wrong, could not process the request";
                    this.error = true;
                    this.creating_transaction = false;
                });
        },
        startListening(ch) {
            this.sockets.subscribe(ch, (e) => {
                let message = e;

                if (message.data.code !== "00") {
                    store.state.momo.message =
                        message.data.system_msg.length > 0 ?
                            message.data.system_msg :
                            message.data.error_message;
                    store.state.momo.failed = true;
                    store.state.momo.progress = false;
                } else {
                    store.state.printURL =
                        store.state.baseUrl + "/printposreceipt/" + store.state.sale_enc_id;
                    store.state.showPrint = true;
                    store.state.momo.progress = false;
                    store.state.momo.complete2 = !store.state.momo.complete2;
                    store.state.momo.requestPendding = false;
                }
            });
        },
        itemChanged(opt) {
            this.cartItems[opt.index].invoice_quantity = opt.quantity;
            this.cartItems[opt.index].amount = opt.amount;
            this.cartItems[opt.index].tax_amount = opt.tax_amount;
            this.cartItems[opt.index].amount_due = opt.amount_due;
            this.cartItems[opt.index].discount_amount = opt.discount_amount;
            this.cartItems[opt.index].description = opt.description;
            this.cartItems[opt.index].applied_taxes = opt.taxes;
            this.cartItems[opt.index].unit_price = opt.unit_price;
        },

        Removeitem(index) {
            this.cartItems.splice(index, 1);
            if (this.cartItems.length === 0) {
                this.total_discount = 0;
                this.temp_discount = 0
            }
        },

        itemTemplate(item) {
            return {
                id: item.id,
                amount: 1,
                tax_amount: 0,
                applied_taxes: [],
                quantity: item.quantity,
                photo: item.photo,
                invoice_quantity: 1,
                unit_price: item.selling_price,
                description: item.description,
                item_unit_price: item.real_unitPrice ? item.real_unitPrice : 0,
                type: item.type,
                net_amount: item.net_amount,
                invoice_amount: item.selling_price,
                selling_price: item.selling_price,
                discount_percent: item.discount_percent,
                track_inventory: item.track_inventory,
                discount_amount: this.determineItemDiscount(item),
                name: item.name,
            };
        },

        addItem(item) {
            if (!this.isVaialable(item)) {
                this.cartItems.push(this.itemTemplate(item));
            }
        },

        debounce(func, timeout = 3000) {
            let timer;
            return (...args) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    func.apply(this, args);
                }, timeout);
            };
        },

        getCategories() {
            window.axios.get("/api/pos/items/categories").then((res) => {
                this.categories = res.data;
                this.categories.unshift(this.all);
            });
        },
        getItems() {
            if (this.$store.state.barcodeMode && !this.search) {
                return;
            }

            this.loading = true;

            let url = new URL(this.$store.state.baseUrl + "/api/pos/items");

            if (!this.$store.state.barcodeMode) {
                if (this.selected_cat) {
                    url.searchParams.append("category_id", this.selectedCategory.id);
                }
                if (this.page) {
                    url.searchParams.append("page", this.page);
                }
            }

            if (this.search) {
                url.searchParams.append("keyword", this.search);
            }

            return window.axios.get(url.href).then((res) => {
                this.items = res.data;
                if (this.$store.state.barcodeMode) {
                    if (this.items.data.length > 0) {
                        this.addItem(this.items.data[0]);
                    }
                    this.$store.state.search = "";
                }
                this.loading = false;
            });
        },
        GetWallet() {
            this.checkingWallet = true;

            window.axios.get("/api/bw/wallet").then((res) => {
                this.checkingWallet = false;

                this.wallet = res.data;
            });
        },

        isVaialable(item) {
            const cartItem = this.cartItems.find((i) => i.id === item.id);

            if (cartItem) {
                const cloneItem = JSON.parse(JSON.stringify(cartItem));

                const index = this.cartItems.findIndex((it) => it.id === cloneItem.id);

                this.cartItems[index].invoice_quantity =
                    Number(this.cartItems[index].invoice_quantity) + 1;

                return true;
            }

            return false;
        },
        getAccounts() {
            window.axios.get("/api/pos/bank-accounts").then((res) => {
                this.accounts = res.data;
            });
        },
    },

    created() {
        if (this.$route.query.s) {
            document.cookie = "token=" + this.$route.query.s;
            window.location = "/";
        }
    },

    mounted() {
        this.payments = [{
            account_id: this.account_id,
            amount: this.amount_due,
        },];

        this.date = this.todayDate();
        this.temp_date = this.todayDate();

        if (this.business.country.code.toLowerCase() === "ke") {
            this.network = "mpesa";
        }

        if (this.SetAccount_id) {
            this.account_id = this.SetAccount_id;
        }

        this.getAccounts();
        this.getCategories();
    },
};
</script>

<style scoped></style>
