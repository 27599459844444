import store from "../store"

export const cut_off_date = () => {
    const business = store.state.user.current_business;
    const cutOffSettings = business.cut_off_date;

    if (!cutOffSettings) {
        return null;
    }

    if (!cutOffSettings.is_on) {
        return null;
    }

    return cutOffSettings.cut_off_date;

}