import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import "./registerServiceWorker";
import VueTour from "vue-tour";
import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";

require("vue-tour/dist/vue-tour.css");

let localhostURl = "http://localhost:2323";
let prodURL = "https://soc-net.builtaccounting.com/";
Vue.use(
  new VueSocketIO({
    connection: SocketIO(prodURL),
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);

Vue.config.productionTip = false;
Vue.use(VueTour);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
  created() {
    if (!this.$vuetify.breakpoint.mobile) {
      store.state.cartSideNav = true;
      store.state.minNav = true;
    }

    if (this.$route.query.s) {
      const token = this.$route.query.s;

      document.cookie = "token=" + token;
      window.location = "/";
    }
    const isDark = localStorage.getItem("theme") === "dark";

    if (isDark) {
      this.$vuetify.theme.isDark = true;
    }

    store.commit("initApp");
  },
}).$mount("#app");

Vue.filter("toMoney", (d) => {
  if (d) {
    return Number(d)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  } else {
    return "0.00";
  }
});
