<template>
  <v-row>


<v-col cols="6" sm="3" v-for="i in 10" :key="i">
  <v-skeleton-loader  type="card"></v-skeleton-loader>
</v-col>

  </v-row>

</template>

<script>
export default {
  name: "ItemsLoading",
  data(){
    return{
      i:1
    }
  }
}
</script>

<style scoped>

</style>