<template>
  <span v-if="$store.state.generalMessage">

  <v-snackbar
      color="error"

      v-model="$store.state.showGeneralMessage"

      transition="slide-x-reverse-transition"
  >

    <template v-if="typeof $store.state.generalMessage ==='string'">
      {{$store.state.generalMessage}}
    </template>
      <template v-else>

    <h3>{{$store.state.generalMessage.message}}</h3>
    <ul
        v-if="$store.state.generalMessage && $store.state.generalMessage.errors"
    >
      <li
          v-for="key in keys"
          :key="key"
      >{{$store.state.generalMessage.errors[key][0]}}</li>
    </ul>
    </template>

    <template v-slot:action="{ attrs }">
      <v-btn
          text
          dark
          v-bind="attrs"
          color="red"
          @click="$store.state.showGeneralMessage = false"
          rounded
      >
        Okay
      </v-btn>
    </template>
  </v-snackbar>
  </span>

</template>

<script>
export default {
  name: "GeneralErrorComponent",
  computed:{
    showError(){
      return this.$store.state.showGeneralMessage;

    },
    keys(){
      return this.$store.state.generalMessage && this.$store.state.generalMessage.errors ?
          Object.keys(this.$store.state.generalMessage.errors) : [];

    }

  },
  watch:{
    showError(){

    }
  }


}
</script>

<style scoped>

</style>