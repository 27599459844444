import Vue from 'vue'
import VueRouter from 'vue-router'
import cart from "../views/Cart";
import Home from "../views/Home";
import itemScan from "../views/itemScan";
import Transactions from "../views/Transactions";
import inventory from "../views/Inventory";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/cart',
    name: 'Cart',
    component:cart
  },{
    path: '/item-scan',
    name: 'ItemScan',
    component:itemScan
  }
,{
    path: '/transactions',
    name: 'Transaction',
    component:Transactions
  },{
    path: '/inventory',
    name: 'Inventory',
    component:inventory
  },
  {
    path: '/transactions/:id',
    name: 'Transaction',
    component:Transactions
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
