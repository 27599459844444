<template>
  <v-card
      class="mx-auto pt-1"
      flat
      @click="$emit('selected',item)"
      title="Click to add item"
  >
    <v-img
        :src="item.photo "
        height="150px"
        class="ma-3"
        style="border-radius: 15px"
    >
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn style="border-radius: 6px !important;" small depressed  color="orange lighten-5 orange--text">

            <v-icon>mdi-cart-plus</v-icon>

          </v-btn>
        </v-card-title>

    </v-img>

    <v-card-text>
      <strong :class="$vuetify.theme.isDark ? 'white--text' : 'black--text'"  class="font-weight-bold">{{item.name}}</strong>

    </v-card-text>

    <v-card-subtitle>
      {{item.description ? item.description : item.name}}
    </v-card-subtitle>

    <v-card-text>

        <h3 class="font-weight-bold" :class="item.discount_percent>0 ? 'cancel-text' : 'orange--text'"> {{business.currency.code}}{{item.selling_price | toMoney}}</h3>
        <h2 class="font-weight-bold orange--text" v-if="item.discount_percent>0">{{business.currency.code}}{{item.net_amount | toMoney}}</h2>
      <small class="text--disabled"> | QTY:{{item.quantity}}</small>  <v-chip class="font-weight-black" v-if="item.discount_percent>0" color="orange lighten-5 orange--text">{{item.discount_percent}}% - OFF</v-chip>

    </v-card-text>

  </v-card>

</template>

<script>
export default {
  props:["item"],
  name: "itemCard",
  computed:{
    business(){


      return this.$store.state.user.current_business;
    }
  }
}
</script>

<style>

.cancel-text{

  text-decoration: line-through;

}

</style>