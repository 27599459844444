<template>

  <v-tour
      name="SideDrawerTour"
      :steps="steps"
      style="transition: 0.5s ease-in-out; border-radius: 15px !important;"
  >


    <template slot-scope="tour">

      <transition name="fade">

        <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            class="orange font-weight-light"
        >

          <template>
            <div slot="actions">
              <v-btn @click="tour.stop(); turnOff() " class="mr-2" dark outlined rounded>Close</v-btn>
              <v-btn v-if="!tour.isLast" @click="tour.nextStep" depressed dark outlined rounded>Next<v-icon>mdi-arrow-down</v-icon></v-btn>

            </div>
          </template>

          <template>
            <div slot="header">
              <h5 class="font-weight-bold">{{tour.steps[tour.currentStep].header.title}}</h5>
            </div>
          </template>
        </v-step>
      </transition>
    </template>

  </v-tour>
</template>
<script>
export default {
  name:"tourComponent",
  data () {
    return {


      steps: [
        {
          target: '#Home_menu',

          header: {
            title: 'POS home or dashboard',
          },
          params: {
            placement: 'right',
            highlight: false
          },
          content: `View summary of your transactions for a day,weeks or months and your cash register for selected periods`
        },
        {
          target: '#Sell_menu',
          content: 'Sell your items and create orders',
          header: {
            title: 'Store front',
          },
          params: {
            placement: 'right',
            highlight: false
          },
        },
        {
          target: '#Transactions_menu',
          content: 'View and retrieve your transactions, preview,find and reprint receipts',
          header: {
            title: 'Transactions history',
          },
          params: {
            placement: 'right',
            highlight: false
          },
        },
        {
          target: '#Inventory_menu',
          content: 'View and find items in your inventory',
          header: {
            title: 'Inventory lookup',
          },
          params: {
            placement: 'right',
            highlight: false
          },
        },

        {
          target: '#dark_mode_menu',
          content: 'Switch to dark theme',
          header: {
            title: 'Dark Mode',
          },
          params: {
            placement: 'right',
            highlight: false
          },
        },
        {
          target: '#logout_menu',
          content: 'Logout and close your session',
          header: {
            title: 'Logout',
          },
          params: {
            placement: 'right',
            highlight: false
          }
        }


      ]
    }
  },
  methods:{
    turnOff(){
      localStorage.setItem('SideDrawerTour',true);
    }
  },
  mounted () {
    if (!localStorage.SideDrawerTour) {

      this.$tours['SideDrawerTour'].start()
    }

  }

}
</script>
<style>
.v-step__arrow--dark{
  background-color:#2196F3 !important ;
}

</style>