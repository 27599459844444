<template>
  <v-card flat>

    <iframe allow="*" ref="printPage" style="width: 100%; height: 100vh; border:none; background-color: #ffffff;"
            :src="$store.state.printURL">
    </iframe>
    <v-card-actions v-if="$vuetify.breakpoint.mobile">
      <v-btn @click="print" color="green" dark block rounded x-large depressed>Print
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-card-actions>

    <v-card-actions>
      <v-btn color="error" x-large @click="$store.state.showPrint=false" dark depressed block rounded>Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  props: ["url"],
  name: "printDialogComponent",
  methods: {

    print() {



    }
  }
}
</script>

<style scoped>

</style>