<template>
  <v-card flat>
    <v-card-title>
      <v-btn text @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
        Back
      </v-btn>
      <v-spacer></v-spacer>
      Find item
    </v-card-title>
    <v-card-text class="text-center" style="padding-top: 100px">

      <div class="purple lighten-4 text-center"
           style="width: 60px !important; height: 60px !important; border-radius: 50%; margin: auto; padding-top: 10px;">
        <v-icon x-large color="purple" style="margin: auto">mdi-cube-scan</v-icon>
      </div>
      <h1 class="black--text ma-3">{{ step === 1 ? 'Scan item for details' : 'Item found' }}</h1>

      <v-stepper style="box-shadow: none" v-model="step">
        <v-stepper-items>
          <v-stepper-content step="1" class="text-center">
            <p>Find the details of an item by scanning the barcode</p>

          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

    </v-card-text>
    <v-card-actions>
      <v-btn @click="scan" x-large rounded depressed block color="purple" dark>Begin Scan</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "itemScan",
  data() {
    return {
      step: 1,
      scanStarted: false
    }
  },
  methods: {
    async didUserGrantPermission() {

    },
    scan() {



    }
  }
}
</script>

<style>


</style>