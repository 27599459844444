<template>
  <span>
    <v-row style="height: 100vh">
      <v-col v-if="!$vuetify.breakpoint.mobile" cols="12" sm="8">
        <center>
          <v-img
            src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/screens.png"
            width="800"
          ></v-img>
          <h1
            class="font-weight-black text-center mt-5"
            style="font-size: 2.5rem"
          >
            WELCOME TO BUILT POS
          </h1>
          <h2 class="font-weight-light text-center mt-5">
            SELL | ACCEPT PAYMENTS INSTANTLY | PRINT RECEIPTS AND MORE
          </h2>
          <p class="font-weight-light text-center mt-5 text--disabled">
            <a
              class="text--disabled mr-5"
              href="https://built.africa"
              referrerpolicy="no-referrer"
              target="_blank"
              >Powered By Built Africa</a
            >
            <a
              class="text--disabled mr-5"
              href="https://built.africa/contact"
              referrerpolicy="no-referrer"
              target="_blank"
              >Help</a
            >
            <a
              class="text--disabled mr-5"
              href="https://built.africa/about"
              referrerpolicy="no-referrer"
              target="_blank"
              >About Us</a
            >
            <a
              class="text--disabled mr-5"
              href="https://built.africa/termsOfService"
              referrerpolicy="no-referrer"
              target="_blank"
              >Terms Of Use</a
            >
          </p>
        </center>
      </v-col>
      <v-col class="pt-5" cols="12" md="4" sm="4" xl="12">
        <v-card class="mt-5" flat style="height: 90vh">
          <v-card-text>
            <center>
              <span
                class="font-weight-light text-center mt-5"
                style="font-size: 2rem"
                >Log in to your POS</span
              >
              <span class="d-block">Log in with your Built user account</span>
            </center>

            <v-form ref="loginForm" class="mt-6">
              <v-text-field
                v-model="email"
                :disabled="progress"
                :rules="[
                  $store.state.validationRules.required,
                  $store.state.validationRules.email,
                ]"
                color="orange"
                flat
                outlined
                placeholder="Email"
                type="email"
                @keydown.enter="login"
              ></v-text-field>
              <v-text-field
                v-model="password"
                :append-icon="
                  password_visible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                "
                :disabled="progress"
                :rules="[$store.state.validationRules.required]"
                :type="password_visible ? 'text' : 'password'"
                color="orange"
                flat
                outlined
                placeholder="Password"
                @keydown.enter="login"
                @click:append="password_visible = !password_visible"
              ></v-text-field>

              <v-btn
                :loading="progress"
                block
                color="orange"
                dark
                depressed
                rounded
                x-large
                @click="login"
                >Login</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>
export default {
  name: "loginComponent",
  data() {
    return {
      email: "",
      password: "",
      password_visible: false,
      progress: false,
    };
  },
  methods: {
    login() {
      if (this.$refs.loginForm.validate()) {
        this.progress = true;
        const formData = new FormData();
        formData.append("email", this.email);
        formData.append("password", this.password);

        window.axios
          .post("/api/auth/login", formData)
          .then((res) => {
            document.cookie = "token=" + res.data.access_token;

            location.reload();

            this.progress = false;
          })
          .catch(() => {
            this.progress = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
