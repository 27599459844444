<template>
  <v-card-text>
    <v-row>
      <v-col>
        <v-alert outlined class="green--text">
          <v-row dense>
            <v-col cols="12" sm="2" class="text-center">
              <v-icon x-large color="green">mdi-party-popper</v-icon>
            </v-col>
            <v-col cols="12" sm="10">
              <h4 class="font-weight-light">
                Find other apps within the Built ecosystem that can be useful in
                other areas.<br />
                Continue with your existing Built user account.
              </h4>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" v-for="(app, index) in appsList" :key="index">
        <v-card outlined :href="app.link" target="_blank">
          <v-img class="ma-1 rounded-lg" contain :src="app.imageURL" max-height="150"></v-img>
          <v-card-title>{{ app.name
            }}<sup><v-icon x-small>mdi-arrow-up-right</v-icon></sup></v-card-title>
          <p class="text-muted">
            {{ app.description }}
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-card-text>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppCardComponent",
  data() {
    return {
      appsList: [
        {
          color: "background-color: rgba(255,165,0,0.08)",
          link: "https://app.built.africa/init?token=" + this.$store.state.getCookie('token'),
          name: "Built",
          description: "",
          imageURL:
            "https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/Screenshot%202024-02-01%20at%2011.47.33%20PM.png",
        },
        {
          color: "background-color: rgba(135,206,235,0.08)",
          link: "https://mypayroll.built.africa/?s=" + this.$store.state.getCookie('token'),
          name: "Built Payroll",
          description: "",
          imageURL:
            "https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/Screenshot%202024-02-02%20at%2012.03.16%20AM.png",
        },
        {
          color: "background-color: rgba(7,60,134,0.08)",
          link: "https://payroll.built.africa/?s=" + this.$store.state.getCookie('token'),
          name: "Employee Portal",
          description: "",
          imageURL:
            "https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/Screenshot%202024-02-02%20at%2012.36.06%20AM.png",
        },
        {
          color: "background-color: rgba(7,60,134,0.08)",
          link: "https://cfoai.built.africa/?s=" + this.$store.state.getCookie('token'),
          name: "CFO AI",
          description: "",
          imageURL:
            "https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/Screenshot%202024-02-02%20at%2012.27.59%20AM.png",
        },
      ],
    };
  },
});
</script>

<style scoped></style>